import { AltLayout } from '../layouts/AltLayout';
import { SEO } from '@/common/seo';
import { StaticImage } from 'gatsby-plugin-image';

const Sunbit: Page = () => (
    <AltLayout
        heroImg="SunbitHero.jpg"
        label="Sunbit Financing"
        heroButtonText="Apply Now"
        heroButtonLink="https://apply.sunbit.com/ZapataDental"
        logo="sunbit-logo.png"
        description="Calculate your monthly payment amount and pay through Sunbit for your Zapata Dental treatment"
    >
        <div className="py-[120px]">
            <a
                href="https://apply.sunbit.com/ZapataDental"
                target="_blank"
                rel="noreferrer"
                className="hover:opacity-70 transition-opacity max-w-max block mx-auto"
            >
                <StaticImage
                    src="../../src/images/sunbit-banner.jpg"
                    alt="apply for sunbit financing"
                    width={1200}
                    height={1200}
                />
            </a>
        </div>
    </AltLayout>
);

export default Sunbit;

export const Head = () => (
    <SEO
        title="Sunbit Healthcare - Financing for Dental Procedures"
        description="Carecredit is a credit card that helps you to pay for treatments and procedures for  you and your family that may not be covered by insurance."
        pathname="/sunbit/"
    />
);
